// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** FONT **/
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/opensans.ttf');//mine
}

@font-face {
  font-family: 'Josefin';
  src: url('../assets/fonts/Josefin.ttf');//mine
}

@font-face {
  font-family: 'Exo2';
  src: url('../assets/fonts/exo2.otf');//mine
}
@font-face {
  font-family: 'Emma Sophia';
  src: url('../assets/fonts/emmasophia.ttf');//mine
}
@font-face {
  font-family: 'Dirty Old Town';
  src: url('../assets/fonts/dirtyoldtown.ttf');//mine
}
@font-face {
  font-family: 'Comfortaa';
  src: url('../assets/fonts/comfortaa.ttf');//mine
}
@font-face {
  font-family: 'Coco Sharp';
  src: url('../assets/fonts/coco_sharp.ttf');//mine
}
@font-face {
  font-family: 'Bubble';
  src: url('../assets/fonts/Bubblegum.ttf');//mine
}
@font-face {
  font-family: 'Ghost Of The WildWest';
  src: url('../assets/fonts/Ghost_of_the_wild_west.ttf');//mine
}
@font-face {
  font-family: 'Justbubble';
  src: url('../assets/fonts/JustBubble.ttf');//mine
}
@font-face {
  font-family: 'Rounded';
  src: url('../assets/fonts/rounded.ttf');//mine
}
@font-face {
  font-family: 'Steelfish';
  src: url('../assets/fonts/steelfish.ttf');//mine
}
@font-face {
  font-family: 'Buffalo';
  src: url('../assets/fonts/buffalo.ttf');//mine
}
@font-face {
  font-family: 'Pointedlymad';
  src: url('../assets/fonts/Pointedlymad.ttf');//mine
}
@font-face {
  font-family: 'Euro Western';
  src: url('../assets/fonts/euro_western.otf');//mine
}
@font-face {
  font-family: 'American Captain';
  src: url('../assets/fonts/american_captain.ttf');//mine
}

@font-face {
  font-family: 'Lovecraft';
  src: url('../assets/fonts/Lovecraft.ttf');//mine
}
@font-face {
  font-family: 'Cabbagetown';
  src: url('../assets/fonts/Cabbagetown.ttf');//mine
}
@font-face {
  font-family: 'Nougat';
  src: url('../assets/fonts/nougat.ttf');//mine
}
@font-face {
  font-family: 'Quacker';
  src: url('../assets/fonts/Quacker.ttf');//mine
}
/*********/
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

h1, h2, h3, h4, h5, h6{
  padding: 0;
  margin: 0 !important;
}

.admin-body{
  min-height: 62vh;
}

.categories{
  overflow-x: auto;
  display: flex;
}
.btn-category{
  // background:  linear-gradient(10deg, rgba(255, 255, 255,0.5) 0%, rgba(255, 255, 255, 0.2) 49%, rgba(0,0,0,0.2) 100%);
  padding: 3px;
  margin: 5px 5px;
  background: none;
  border : none;
  .icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    border : 3px solid red;
    margin-bottom: 10px;
  }
  img{
    width : 50px;
    height : 50px;
  }
  h5{
    padding: 0;
    font-size: 20px;
  }

}

.wrapper{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0px 1%;
  .one-product p, .one-product h4, .one-product h5{
    color : inherit;
  }
}
.center{
  text-align: center;
}


.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 10px;
  display: block;
  // background:  linear-gradient(10deg, rgba(255, 255, 255,0.5) 0%, rgba(255, 255, 255, 0.2) 49%, rgba(0,0,0,0.2) 100%);
  *, p{
    color : inherit;
  }
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.btn{
  padding: 10px 15px;
  border-radius : 15px;
  font-size : 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ion-icon{
    font-size : 20px;
  }
}

.btn-default{
  background: red;
  color : white;
}
ion-list-header{
     display:block;
     text-align: center;
}

#logo-menu{
  width: 400px;
}

ion-menu{
  ion-list {
    background: transparent;
  }
  border : none !important;
}
ion-menu.md, ion-list{
  border : none !important;
}


ion-menu {
  --ion-background-color: transparent;
  --ion-text-color: white;

  ion-toolbar {
    --background: transparent;
  }
  ion-list {
    background: transparent;
  }
}
/*
*/
#modal-cart{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top:0;
  left: 0;
  padding-top: 10px;
  z-index : 20000000;
  .close-button{
    position: absolute;
    right : 10px;
    top : 10px;
  }
  .card{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 25px;
    max-height: 85%;
    position: relative;
    .card-body{
      text-align: center;
      overflow-y: auto;
      width: 100%;
      .img-container{
        position: relative;
        margin: auto;
      }
    }
  }
  .actions{
    button{
      width : 80px;
      height : 80px;
      border : 3px solid red;
      border-radius : 5px;
      margin: 0px 5px;
      background: white;
      h5, h6{
        color : red;
        font-size : 1.2em;
        padding: 0;
        margin: 0;
      }
    }
  }
}
#popup-cart{
  position: fixed;
  bottom: 20px;
  left: 0;;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  .infos{
    max-width: 80%;
  }
  .img-container{
    text-align: center;
    max-width: 20%;
    .popupcart-img{
      width: 150px;
    }
  }
  .card{
    width: 90%;

  }
}

#popup-cart, #modal-cart{
  .close-button{
    position: absolute;
    right: -10px;
    top: -10px;
    width : 35px;
    border-radius: 50px;
    height : 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size : 15px;
    font-weight: bold;
    color : black;
    background: white;
    background: white;
  }
}

.one-cart{
  margin-bottom: 10px;
}
.one-cart .card-body{
  display: flex;
  align-items: flex-start;
  padding: 5px;
}

.one-cart .infos{
  width : 80%;
  padding: 0px 10px;
  h3, h4{
    margin: 0;
    padding: 0;
    font-size : 15px;
  }
  h5{
    float:right;
    border : 1px solid black;
    padding: 5px;
    border-radius : 5px;
    border-width: 3px;
    border-style: solid;
  }
}


.one-cart .img-container{
  width : 20%;
}

.list-none{
  margin: 0;
  padding: 0;
  li{
    list-style-type: none;
  }
}

.text-legale{
  padding: 0px 10px;
  h2, h1{
    text-align: center;
    color : inherit;
  }
  h2{
    margin-top: 30px !important;
    margin-bottom: 10px !important;
  }
  p{
    text-align: justify;
    color : inherit;
  }
}

.return-button{
  position: absolute;
  right: 0;
  background: none;
  font-size :30px;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

.alert-info{
  color: #004085;
  background-color: #cce5ff !important;
  border-color: #b8daff;
}
.thumbnail{
  max-width: 64px;
  max-height: 64px;
}

ul{
  padding: 0;
  margin: 0;
  li{
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

ion-list{
  padding: 0 !important;
  margin: 0 !important;
  --background : inherit;
  ion-item{
    --background : inherit;
    --color : inherit;
    color : inherit;
    ion-label{
      --color : inherit;
      color : inherit;
      font-weight: bold;
    }
  }
}

main{
  background: blue;
}

#container{
  background-color: transparent;
}
ion-content{
  background-position: center;
  background-size: 100%;
}

.sidebar-container{
  background-color: #343a40;
  height: 100vh;
}

.cut-text {
  width: 100%;
  display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   height: 2.4em !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    line-height: 1.2em !important;
}

input{
  border: 3px solid black;
  border-radius : 4px;
  padding: 5px;
  margin: 0;
  max-width: 100%;
}

.user-form{
  padding-top: 30px;
  li{
    margin-bottom: 16px;
  }
  .button-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.user .card{
  position: relative;
}
.disconnect{
  width : 40px;
  height : 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right : 10px;
  top : 10px;
  border-radius : 40px;
  ion-icon {
    font-weight: bold;
    width: 30px;
    height: 30px;
  }
}

.alert{
  background: inherit;
  font-size : inherit;
  font-weight : inherit;
  font-family : inherit;
}

#login{
  .form-group{
    width: 100%;
    margin-bottom: 15px;
    input, button{
      width: 100%;
    }
    input{
      padding: 10px;
      border-radius: 10px;
    }
    button{
      font-size : 20px;
      padding: 10px;
    }
  }

  .card{
    margin: 20px;
    .card-body{

      h1{
        text-align: center;
        margin-bottom: 10px;
        margin-top:  10px;
      }
      h6{
        margin-top: 10px !important;
        text-decoration: underline;
        text-align: center;
      }
    }
  }

  .card{
    margin: 10px 20px !important;
    margin-bottom: 20px;
  }
  .profil-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size : 80px;
    .circle{
      border : 3px solid black;
      overflow: hidden;
      border-radius : 100px;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
  h1{
    text-align: center;
  }

  .submenu-button{
    background: none;
    ion-icon{
      font-size: 20px;
    }
  }

  .user{

  }

  .one-order{
    border : 1px solid grey;
    border-radius : 5px;
    margin: 5px;
    padding: 5px;
    position: relative;
    .price-container{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style-type: none;

      }
    }
    .view-icon{
      position:absolute;
      top : 0;
      right : 0;
      background: none;
      border : none;
      ion-icon{
        font-size : 32px;
        color : inherit;
      }
    }
  }

  .user-order-list{
    width: 100%;
    .one-order{
      td:first-child{
        width : 20%;
        text-align: center;
      }
      td.price{
        width : 20%;
      }
      h5{
        font-size : 16px;
      }
      h6{
        font-size : 14px;
      }
      .price{
        font-size : 20px;
      }
      li{
        font-size: 12px;
      }
    }

  }
  .total-amount{
    margin-top: 10px !important;
    text-align: right;
    font-size : 20px;
  }

  .supplement-block{
    padding-left: 10px;
  }

  .one-fidelity{
    width : 64px;
    height : 64px;
    border : 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    img{
      width:120px;
      position: absolute;
    }
  }
  .one-fidelity:first-child img{ transform: rotate(320deg); }
  .one-fidelity:nth-child(2) img{ transform: rotate(90deg); }
  .one-fidelity:nth-child(3) img{ transform: rotate(10deg); }
  .one-fidelity:nth-child(4) img{ transform: rotate(180deg); }
  .one-fidelity:nth-child(5) img{ transform: rotate(120deg); }
  .one-fidelity:nth-child(6) img{ transform: rotate(10deg); }
  .one-fidelity:nth-child(7) img{ transform: rotate(300deg); }
  .one-fidelity:nth-child(8) img{ transform: rotate(10deg); }
  .one-fidelity:nth-child(9) img{ transform: rotate(200deg); }
  .one-fidelity:nth-child(10) img{ transform: rotate(70deg); }

  .card-body-fidelity{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .disconnect{

  }

  .fidelity-success{
    padding: 5px;
    border-radius : 10px;
  }

  .user-form{
    li{
      text-align : center;
    }
  }
}

.modal-container{
  width: 100%;
  height: 100%;
  position: fixed;
  top : 0;
  left : 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30000;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal{
    width: 90%;
    min-height: 50%;
    max-height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 15px 10px;
    position: relative;
    .modal-close-button{
      position: absolute;
      padding: 5px;
      right: 0px;
      top : 0px;
      background: none;
      border: none;
      font-size: 30px;
    }
  }
}

.alert-danger{
  color: #a94442 !important;
background-color: #f2dede;
border-color: #ebccd1;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #modal-cart{
    .product-image{
      width: 150px;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

ion-header {
  background-color: white;
  padding-top: var(--ion-safe-area-top) !important;
}
